<template>
  <div class="view pa24">
    <div class="d-flex">
        <el-button class="ma" type="primary" @click="handleAddDealer('add')">+添加经销商</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :total="total"
      :currentPage="pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>

        <el-table-column
          prop="dealerName"
          align="center"
          label="经销商名称"
        />
        <el-table-column
            prop="code"
            align="center"
            label="代号"
        />
        <el-table-column
          prop="liaison"
          align="center"
          label="联系人"
        />
        <el-table-column
          prop="phone"
          align="center"
          label="电话"
        />
        <el-table-column align="center" width="200" label="经销商地址">
          <template slot-scope="scope">
              {{scope.row.province}}{{ scope.row.city}}{{ scope.row.county}}
          </template>
        </el-table-column>
        <el-table-column align="center" width="200" label="销售区域">
          <template slot-scope="scope">
             <div v-for="item in scope.row.area" :key="item.id">
               {{item.address}}
             </div>
          </template>
        </el-table-column>

        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editDealar(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteDealar(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <AddDealerDialog ref="addDealerDialog" @getList="getList"></AddDealerDialog>
  </div>
</template>

<script>
import AddDealerDialog from './components/AddDealerDialog'
import commonTable from "@/components/common/commonTable";
import { fetchDealList, fetchDeleteDeal } from '@/api/companyManage.js'

export default {
  name: "dealerList",
  components: {
    commonTable,
    AddDealerDialog,
  },
  data() {
    return {
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
    };
  },
  created() {
    this.getList()
  },
  methods: {
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      handleAddDealer(type, id) {
          this.$refs.addDealerDialog.openDialog(type, id)
      },
      getList() {
        let params={
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type:2,
          companyId: JSON.parse(localStorage.getItem('info')).companyId,
        }
        fetchDealList(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
      },
      editDealar(data) {
        this.$refs.addDealerDialog.openDialog('edit', data)
      },
      deleteDealar(data) {
        let params={
          id: data.id
        }
        this.$confirm('此操作将永久删除该经销商, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          fetchDeleteDeal(JSON.stringify(params)).then(res => {
            if(res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }
  }
}
</script>

<style lang="scss" scoped></style>

